@font-face {
    font-family: 'Source Code Pro';
    src: url('../fonts/source_code_pro/SourceCodePro-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Abel';
    src: url('../fonts/abel/Abel-Regular.ttf') format('truetype');
}

  .follow-content {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding: 16vh 10vw 16vh 10vw;
    background-color: black;
  }

  .follow-title {
    font-size: 3em;
    font-family: Abel, monospace;
    color: white;
    width: 30%;
  }

  .follow-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    margin-top: 5vh;
    margin-right: 5%;
  }
  
  .follow-text {
    font-size: 1.1em;
    margin-bottom: 3vh;
    line-height: 2;
    color: white;
    font-family: Source Code Pro, monospace;
  }
  
  .contact-button {
    padding: 2.5vh 7.5vh;
    font-size: 1em;
    font-weight: bolder;
    background-color: transparent;
    border: 1px solid white;
    cursor: pointer;
    font-family: Source Code Pro, monospace;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  }

  .contact-button {
    color: white;
    text-decoration: none;
  }

  .contact-button:hover {
    background-color: white;
    color: black;
    text-decoration: none;
  }

  @media screen and (max-width: 800px){
    .follow-content {
      display: none;
    }
  }

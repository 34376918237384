@font-face {
    font-family: 'Source Code Pro';
    src: url('../fonts/source_code_pro/SourceCodePro-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Abel';
    src: url('../fonts/abel/Abel-Regular.ttf') format('truetype');
}

.about-me {
    height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  
  .about-me h1 {
    font-size: 60px;
    font-family: Abel, monospace;
    font-weight: 100;
    margin-left: 10vw;
  }
  
  .about-me p {
    font-size: 26px;
    line-height: 1.6;
    font-family: Abel, monospace;
    margin-left: 10vw;
    width: 45vw;
  }
  
  .about-me .about-me-img {
    position: absolute;
    top: 10%;
    left: 65vw;
    width: 25vw;
    z-index: -1;
    border-radius: 20px;
  }

  .about-me p div {
    display: flex;
    flex-direction: row;
  }

  #applause, #ted {
    width: 150px;
    height: 100px;
    margin: 40px 20px;
  }

  #nu {
    width: 250px;
    height: 100px;
    margin: 40px 20px;
  }

  @media screen and (max-width: 1200px){

    #applause, #ted {
      width: 90px;
      height: 60px;
    }

    #nu {
      width: 150px;
      height: 60px;
    }

    .about-me .about-me-img{
      display: none;
    }

    .about-me p {
      width: 80%;
      font-size: 20px;
    }
  }
@font-face {
    font-family: 'Source Code Pro';
    src: url('../fonts/source_code_pro/SourceCodePro-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Abel';
    src: url('../fonts/abel/Abel-Regular.ttf') format('truetype');
}
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2vw 10vw;
  }
  
  .footer-content p {
    font-size: 3vh;
    font-family: Abel, monospace;
  }
  
  .contact-info {
    display: flex;
    font-size: 2vh;
    font-family: Source Code Pro, monospace;
  }
  
  .contact-info a, .contact-info div{
    margin: 4vh;
    color:black;
    text-decoration: none;
  }
  
  .contact-info a:hover {
    text-decoration: underline;
    text-underline-offset: 10px;
    text-decoration-thickness: 1px;
  }

  @media screen and (max-width: 1000px){

    .footer-content {
      display: none;
    }
  }
@font-face {
    font-family: 'Source Code Pro';
    src: url('../fonts/source_code_pro/SourceCodePro-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Abel';
    src: url('../fonts/abel/Abel-Regular.ttf') format('truetype');
}

.navigator {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 3vw;
    margin-right: 3vw;
  }

  .navigator a:link, .navigator a:visited{
    text-decoration: none;
  }
  
  .navigator ul {
    display: flex;
  }
  
  .navigator li {
    list-style-type: none;
    font-family: Abel, monospace;
    font-size: 40px;
    color: black;
  }

  .navigator li div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    align-items: center;
    color: black;
    text-decoration: none;
  }

  .navigator li div * {
    padding: 5px 15px;
}

@media screen and (max-width: 800px) {

  .navigator li {
    list-style-type: none;
    font-family: Abel, monospace;
    font-size: 20px;
    color: black;
    padding-bottom: 20px;
  }

  .navigator li div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    align-items: center;
    color: black;
    text-decoration: none;
  }

  .navigator li div * {
    padding: 2px 5px 2px 5px;
}

}
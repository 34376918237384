.container {
    width: 50%;
    height: 55vh;
    display: flex;
    flex-direction: column;
    
}

.container p {
    font-size: 1.8em;
    font-family: Abel, monospace;
    text-align: left;
    margin: 5% 10% 5% 10%;
}

.container .img-box {
    display: inline-block; /* shrink wrap to image */
    overflow: hidden; /* hide the excess */
    width: 80%;
    height: 22.5vw;
    margin-left: 10%;
    margin-right: 10%;
  }

.container .img-box img {
    display: inline-block; /* shrink wrap to image */
    overflow: hidden; /* hide the excess */
    transition: 0.3s ease-in-out;
    width: 100%;
    height: 100%;
}

.container .img-box:hover img {
    transform: scale(1.2);
}

@media screen and (max-width: 800px) {
    .container {
        width: 100%;
        height: 40vh;
    }

    .container p {
        font-size: 1.5em;
    }

    .container .img-box {
        display: inline-block; /* shrink wrap to image */
        overflow: hidden; /* hide the excess */
        width: 80%;
        height: 45vw;
        margin-left: 10%;
        margin-right: 10%;
        margin-bottom: 0;
      }
}